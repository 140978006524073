<template>
  <div>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="dark"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <b-button :disabled="disabled" variant="success" @click="sortCategory">اعمال تغییرات</b-button>
      <button class="mr-3 ml-2">عمق نمایش</button>
      <select v-model="depth">
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
      </select>

    <b-card title="دسته‌بندی‌ها" class="mt-3">
      <template v-if="realItems != null">
        <VueNestable v-model="realItems" rtl>
          <VueNestableHandle slot-scope="{ item }" :item="item">
            <span style="user-select: none;"> <i class="fa fa-arrows-v" style="font-size:11px"></i> {{ item.id + ' - ' + item.name }}</span>
          </VueNestableHandle>
        </VueNestable>
      </template>
      <div v-else>
        در حال بارگزاری
      </div>
    </b-card>
  </div>
</template>
<script>
import mixins from '../mixins/mixins'
import '../../../assets/admin/vue-nestable.css'

export default {
  mixins: [mixins],
  name: 'CategorySort',
  data() {
    return {
      url: '/api/admin/categories',
      realItems: null,
      title: 'دسته بندی ها',
      depth: 1
    }
  },
  watch: {
      items(newItems) {
        newItems = newItems.data
        this.onDepthChange(newItems)
      },
    depth() {
        this.onDepthChange(this.items.data)
    }
  },
  methods: {
    onDepthChange(newItems) {
      if (this.depth == 1) {
        this.realItems = newItems.map(newItem => {
          return {
            id: newItem.id,
            name: newItem.name,
            children: []
          }
        })
      } else if (this.depth == 2) {
        this.realItems = newItems.map(newItem => {
          return {
            id: newItem.id,
            name: newItem.name,
            children: newItem.children.map(temp => {
              return {
                id: temp.id,
                name: temp.name,
                children: []
              }
            })
          }
        })
      } else {
        this.realItems = newItems.map(newItem => {
          return {
            id: newItem.id,
            name: newItem.name,
            children: newItem.children
          }
        })
      }
    },
    async sortCategory() {
      let sanitizedCategories = this.realItems.map(i => this.sanitizeCategory(i))
      try {
        this.disabled = true;
        const response = await this.$axios.post('api/admin/categories/sort', {
          categories: sanitizedCategories,
        })
        this.$root.success_notification(response.data.message)
        this.$router.go()
        this.change = false
      } catch (error) {
        this.$root.error_notification(error)
      }
      this.disabled = false
    },
    sanitizeCategory(c) {
      return {
        id: c.id,
        children: c.children.map((cc) => this.sanitizeCategory(cc))
      }
    }
  },
  created() {
    this.loadItems()
    this.searchParams = {
      name: '',
      status: '',
      special: ''
    }
  }
}
</script>
